import { useMutation } from '@tanstack/react-query';
import { client } from 'src/app/services/client';

import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg';
import EntryWrapper from 'src/app/core/components/entry-wrapper/EntryWrapper';
import LoginForm from './components/LoginForm';
import ThirdParties from 'src/app/core/components/entry-wrapper/ThirdParties';

import showMessage from 'src/app/core/utils/show-message';
import useLogin from 'src/app/hooks/useLogin';

import './Login.css';

interface Values {
  email: string;
  password: string;
}

export default function Login() {
  const { loginUser } = useLogin();
  const { isPending, mutate, error } = useMutation({
    mutationFn: client.authenticate,
  });

  const onFinish = (values: Values) => {
    mutate(
      {
        strategy: 'local',
        ...values,
      },
      {
        onSuccess(data) {
          loginUser(data);
          showMessage('success', 'login successfully');
        },
        onError(response) {
          showMessage('error', response.message, error?.message);
        },
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <EntryWrapper>
      <div className='login-container'>
        <a href='/'>
          <Logo />
        </a>

        <div className='login'>
          <p className='login-text'>Login</p>
          <p className='login__welcome-back'>Welcome back! Please enter your details.</p>
          <LoginForm
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            loading={isPending}
          />
          <ThirdParties page='login' />
          <div className='signup'>
            Don’t have an account? <a href='/register'>Sign up</a>
          </div>
        </div>
      </div>
    </EntryWrapper>
  );
}
