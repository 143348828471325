import { RouteObject } from 'react-router-dom';
import { Main } from '../core/components';
import { Home } from '../features/home';
import Property from '../features/property/Property';
import { PropertyDetails } from '../features/property-details';
import AboutUs from '../features/about-us/AboutUs';
import Signup from '../features/signup/Signup';
import Login from '../features/login/Login';
import Auth from '../features/auth';
import AdminDashboard from '../features/admin/AdminDashboard';
import Dashboard from '../features/admin/dashboard/Dashboard';
import ViewProperties from '../features/admin/property/ViewProperties';
import ViewProperty from '../features/admin/property/Property';
import ListProperty from '../features/admin/property/ListProperty';
import ViewUsers from '../features/admin/user/ViewUsers';

const routesObject: RouteObject[] = [
  { path: '/oauth', element: <Auth /> },
  {
    path: '/register',
    element: <Signup />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <Main />,
    children: [
      { index: true, element: <Home /> },
      { path: 'property', element: <Property /> },
      { path: 'property/:id', element: <PropertyDetails /> },
      { path: 'about-us', element: <AboutUs /> },
    ],
  },
  {
    path: '/dashboard',
    element: <AdminDashboard />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: 'properties', element: <ViewProperties /> },
      { path: 'properties/:id', element: <ViewProperty /> },
      { path: 'properties/create', element: <ListProperty /> },
      { path: 'users', element: <ViewUsers /> },
      { path: 'users/:id', element: <ViewProperty /> },
      { path: 'users/create', element: <ListProperty /> },
      { path: 'saved-properties', element: <div>Saved Property</div> },
      {
        path: '*',
        element: <div> 404 👀</div>,
      },
    ],
  },
];

export default routesObject;
