import { Affix, Row } from 'antd'
import { SearchBox } from '../../core/components'

import './Home.css'
import Property from '../property/Property'

export default function Home() {
  return (
    <div className='home-container'>
      <Affix offsetTop={0}>
        <Row className='search-container home-screen-padding'>
          <SearchBox />
        </Row>
      </Affix>
      <Row justify='center' className='home page-content home-screen-padding' gutter={[0, 0]}>
        <Property />
      </Row>
    </div>
  )
}
