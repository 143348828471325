import { useTokenDispatch } from '../providers/TokenProvider';
import { useNavigate } from 'react-router-dom';

export default function useLogin() {
  const navigate = useNavigate();
  const setAppTokens = useTokenDispatch();

  const loginUser = (res: any) => {
    setAppTokens({
      accessToken: res.accessToken,
    });

    navigate({ pathname: '/oauth', search: res.user.user });
  };

  return { loginUser };
}
