import storage from './storage';
import history from './history';

const currentPath = window.location.pathname;
export function cleanupStorage() {
  storage.clear();
}

export default function logout(from = '/') {
  cleanupStorage();
  history.replace(`${currentPath}`, { from });
}

export function goToPreviousRoute() {
  cleanupStorage();
  history.go(-1);
}

export function signOut() {
  window.location.replace('/oauth');
  cleanupStorage();
}
