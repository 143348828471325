import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToken } from 'src/app/providers/TokenProvider';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Auth() {
  const token = useToken();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!token) {
      navigate('/');
      return;
    }

    navigate('/', { replace: true });
  }, [navigate, token]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  );
}
