import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, App as AntApp } from 'antd';
import { Provider } from 'react-redux';
import { store } from './core/store/store';
// import AdminDashboardRoutes from './features/admin/AdminDashboardRoutes';

import appTheme from './theme';
import QueryProvider from './providers/QueryProvider';
import './App.css';
import Routes from './routes';
import TokenProvider from './providers/TokenProvider';

function App() {
  return (
    <React.StrictMode>
      <QueryProvider>
        <TokenProvider>
          <Provider store={store}>
            <BrowserRouter>
              <AntApp>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: appTheme.colorPrimary,
                      controlHeight: 42,
                    },
                  }}
                >
                  <Routes />
                </ConfigProvider>
                {/* Admin */}
                {/* <AdminDashboardRoutes /> */}
              </AntApp>
            </BrowserRouter>
          </Provider>
        </TokenProvider>
      </QueryProvider>
    </React.StrictMode>
  );
}

export default App;
