import { Affix, Breadcrumb, Button, Col, Input, Layout, Row } from 'antd';
import { AppFooter, AppHeader } from 'src/app/core/components';
import AdminSider from './components/AdminSider';
import AdminContent from './components/AdminContent';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectSidebarCollapsed, toggleSidebar } from './store/navigationSlice';
import AdminHeaderMenu from './components/AdminHeaderMenu';
import { CloseOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';

import './AdminDashboard.css';
import './Responsive.css';
import { NavLink, useLocation } from 'react-router-dom';
import useAuth from 'src/app/hooks/useAuth';
import { useEffect } from 'react';
import { goToPreviousRoute } from 'src/app/lib/logout';

export default function AdminDashboard() {
  const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);
  const dispatch = useAppDispatch();
  let { pathname } = useLocation();
  pathname = pathname.replace('/', '');

  const isLoggedIn = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      goToPreviousRoute();
    }
  }, [isLoggedIn]);

  return (
    <Layout className='layout-dashboard'>
      {isLoggedIn && (
        <>
          {' '}
          <AdminSider />
          <Layout className='content-layout'>
            <Affix offsetTop={0}>
              <AppHeader classNames={['admin-header ant-header-fixed']}>
                <Row gutter={[24, 0]}>
                  <Col span={24} md={6}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <NavLink to='/'>Pages</NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>{pathname.replace('/', '')}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='ant-page-header-heading'>
                      <span
                        className='ant-page-header-heading-title'
                        style={{ textTransform: 'capitalize' }}
                      >
                        {pathname.replace('/', ' ')}
                      </span>
                    </div>
                  </Col>
                  <Col span={24} md={18} className='header-control'>
                    <AdminHeaderMenu />
                    <Button
                      onClick={() => {
                        dispatch(toggleSidebar(!sidebarCollapsed));
                      }}
                      icon={sidebarCollapsed ? <MenuOutlined /> : <CloseOutlined />}
                      className='sidebar-toggler'
                      type='link'
                    ></Button>
                    <Input
                      className='header-search'
                      placeholder='Type here...'
                      prefix={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </AppHeader>
            </Affix>
            <AdminContent />
            <AppFooter />
          </Layout>
        </>
      )}
    </Layout>
  );
}
