import * as React from 'react';
import storage from '../lib/storage';
import useUpdatedEffect from '../hooks/useUpdatedEffect';
import { TOKEN_STORAGE_KEY } from '../lib/variables';

export type TokenContextType = {
  accessToken?: string;
  refreshToken?: string;
};

const TokenContext = React.createContext<TokenContextType | undefined>(undefined);
TokenContext.displayName = 'TokenContext';

const TokenDispatchContext = React.createContext<
  React.Dispatch<React.SetStateAction<TokenContextType | undefined>>
>(() => {});
TokenDispatchContext.displayName = 'TokenDispatchContext';

export default function TokenProvider(props: React.PropsWithChildren<any>) {
  const [token, setToken] = React.useState<TokenContextType | undefined>(
    () => storage.get(TOKEN_STORAGE_KEY) ?? undefined
  );

  useUpdatedEffect(() => {
    if (token) {
      storage.set(TOKEN_STORAGE_KEY, token);
    }
  }, [token]);

  return (
    <TokenDispatchContext.Provider value={setToken}>
      <TokenContext.Provider value={token}>{props.children}</TokenContext.Provider>
    </TokenDispatchContext.Provider>
  );
}

export function useToken() {
  return React.useContext(TokenContext);
}

export function useTokenDispatch() {
  return React.useContext(TokenDispatchContext);
}
