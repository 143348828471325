import { Badge, Button, Dropdown, MenuProps } from 'antd'
import { Link } from 'react-router-dom'
import { UserOutlined, LogoutOutlined, EditOutlined, SettingOutlined, BellOutlined } from '@ant-design/icons'

export default function AdminHeaderMenu() {
  const profileMenuitems: MenuProps['items'] = [
    {
      label: <Link to={'#'}>Edit Profile</Link>,
      key: '0',
      icon: <EditOutlined />
    },
    {
      label: <Link to={'#'}>Account Settings</Link>,
      key: '1',
      icon: <SettingOutlined />
    },
    {
      type: 'divider'
    },
    {
      label: 'Logout',
      key: '3',
      icon: <LogoutOutlined />
    }
  ]
  return (
    <>
      <div className='header-control'>
        <Badge size='default' count={5}>
          <Button icon={<BellOutlined />} shape='circle' type='link' />
        </Badge>
        <Dropdown trigger={['click']} menu={{ items: profileMenuitems }}>
          <Button icon={<UserOutlined />} shape='circle' type='link'>
            <span className='user-name'>Admin</span>
          </Button>
        </Dropdown>
      </div>
    </>
  )
}
