import * as React from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Outlet } from 'react-router-dom';
import { AppDrawer, AppHeader, AppLogo, LeftMenu, RightMenu } from '../header';
import { AppFooter } from '../footer';

import './Main.css';
import useAuth from 'src/app/hooks/useAuth';
import { Avatar, Badge, Button, Divider, Dropdown, MenuProps, Space, theme } from 'antd';
import { ProfileOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { signOut } from 'src/app/lib/logout';

const { useToken } = theme;

const items: MenuProps['items'] = [
  {
    label: <a href='/dashboard'>Dashboard</a>,
    key: '0',
    icon: <ProfileOutlined />,
  },
];

export function Main() {
  const isLoggedIn = useAuth();
  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  return (
    <Layout className='layout'>
      <AppHeader>
        <AppLogo />
        <LeftMenu className='main-menu navigation' />
        {!isLoggedIn ? (
          <>
            <RightMenu className='main-menu auth' />
            <AppDrawer />
          </>
        ) : (
          <Dropdown
            placement='bottomLeft'
            menu={{ items }}
            trigger={['click']}
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                <Divider style={{ margin: 0 }} />
                <Space style={{ padding: 8 }}>
                  <Button
                    type='text'
                    icon={<LogoutOutlined />}
                    danger
                    onClick={() => signOut()}
                  >
                    Log Out
                  </Button>
                </Space>
              </div>
            )}
          >
            <Space size={24}>
              <Badge dot>
                <Avatar shape='square' icon={<UserOutlined />} />
              </Badge>
            </Space>
            {/* </a> */}
          </Dropdown>
        )}
      </AppHeader>
      <Content className='site-content'>
        <Outlet />
      </Content>
      <AppFooter />
    </Layout>
  );
}
